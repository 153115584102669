<template>
  <b-card title="List Dealer">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="8" />
        <b-col cols="12" md="4">
          <b-form-input
            v-model="search"
            @input="(value) => doFiltering(value)"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :items="dealers"
            :fields="fields"
            :filter="search"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(overdueAmount)="row">
              {{ row.item.overdueAmount.$numberDecimal }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    @click="doViewDealer(row)"
                    variant="info"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item  v-if="permission.edit">
                  <b-button
                    @click="doEditDealer(row)"
                    variant="outline-primary"
                    class="mr-1"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import lodash from "lodash";
import { userAccess } from "@/utils/utils";
export default {
  data() {
    return {
      fields: [
        {
          key: "dealerCode",
          label: "Dealer Code",
          filterable: true,
          sortable: true,
        },
        { key: "dealerName", label: "Name", filterable: true, sortable: true },
        { key: "overdueAmount", label: "Overdue Amount" },
        { key: "overdueDays", label: "Overdue Day" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Action" },
      ],
      search: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      getDealers: "dealer/getDealerWithQuery",
    }),
    queryDealer() {
      this.getDealers({
        page: this.currentPage,
        entry: this.perPage,
        dealerName: this.search,
      }).then((data) => {
        this.processMetadata(data.metadata);
      });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.queryDealer();
    },
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.queryDealer();
    }, 500),
    processMetadata(metadata) {
      console.log("metadata", metadata);
      let { totalData } = metadata;
      console.log("totalData", totalData);
      this.totalRows = totalData;
      console.log("this.totalRows", this.totalRows);
      this.tableLoading = false;
    },
    doViewDealer(row){
      this.$router.push(`/dealer/view/${row.item._id}`);
    },
    doEditDealer(row) {
      this.$router.push(`/dealer/edit/${row.item._id}`);
    },
  },
  computed: {
    ...mapGetters({
      dealers: "dealer/getDealers",
    }),
    permission() {
      return userAccess("Dealer", "masterdata_menu");
    },

  },
  created() {
    document.title = "Dealer | RSP";
    this.getDealers({
      page: this.currentPage,
      entry: this.perPage,
    }).then((data) => this.processMetadata(data.metadata));
  },
};
</script>
